import { Box, CircularProgress } from "@mui/material";
import React from "react";

export default function LoadProgress({ loading }) {
  return (
    <React.Fragment>
      {loading && (
        <Box sx={{ paddingTop: "5px", paddingBottom: "5px", display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </React.Fragment>
  );
}
