import React, { useEffect, useRef } from "react";
import { Box, Button } from "@mui/material";
import useTranslation from "next-translate/useTranslation";
import throttle from "lodash.throttle";
export default function LoadMore({ nextPage='', loading=false, fetchMoreFunction }) {
  const {t, lang} = useTranslation('common');
  const bt = useRef();
  let offset = 0;
  let throttleMilliseconds = 300;
  const onScroll = throttle(() => {
    if (!bt.current) {
      return;
    }
    const top = bt.current.getBoundingClientRect().top;
    if (top + offset >= 0 && top - offset <= window.innerHeight) {
      fetchMoreFunction();
    }
  }, throttleMilliseconds);

  useEffect(() => {
    document.addEventListener('scroll', onScroll, true);
    return () => document.removeEventListener('scroll', onScroll, true);
  });
  return (
    <React.Fragment>
      {nextPage && !loading && (
        <Box sx={{ paddingTop: "5px", paddingBottom: "5px", display: "flex", justifyContent: "center" }}>
          <Button ref={bt} onClick={fetchMoreFunction}>{t("show_more")}</Button>
        </Box>
      )}
    </React.Fragment>
  );
}
