import { Avatar, Button, Divider, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import Link from "next/link";
import translit from "./translit";
import { BACKEND_URL } from "./settings";
import PageviewIcon from "@mui/icons-material/Pageview";
import LoadMore from "./loadmore";
import LoadProgress from "./loadprogress";
import Similar from "./similar";
import React from "react";
import useTranslation from "next-translate/useTranslation";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";

export default function ShowFilteredVarieties({filteredVarieties, loading, nextPage,fetchMoreVarieties}) {
  const { t, lang } = useTranslation('plant')
  const router = useRouter()
  const { plant } = router.query;
  const search = useSelector((state) => state.search);
  return (
    <React.Fragment>
      <List>
        {filteredVarieties &&
        filteredVarieties.map((variety) => {
          return (
            <Link
              href={`/${encodeURIComponent(variety.plant.slug)}/${encodeURIComponent(variety.slug)}`}
              passHref
              key={variety.id}
            >
              <div>
                <ListItem
                  button
                  component="a"
                  // onClick={(e) => console.log("click")}
                >
                  <ListItemAvatar sx={{ minWidth: "76px" }}>
                    <Avatar
                      sx={{ width: "60px", height: "60px" }}
                      variant="rounded"
                      alt={lang === 'en' ? translit(variety.name) : variety.name}
                      src={
                        variety.previewphoto
                          ? BACKEND_URL +
                          "/img/180/" +
                          variety.previewphoto.split("/").pop()
                          : ""
                      }
                    >
                      {" "}
                      <PageviewIcon/>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={lang === 'en' ? variety.name_eng : variety.name}
                    secondary={((lang === 'en' ? translit(variety.hybridizer) : variety.hybridizer) || variety?.avml?.hybridizer) + (variety.year_sel !== null ? ", " + variety.year_sel : "")}
                  />
                </ListItem>
                <Divider
                  variant="inset"
                  component="li"
                  style={{ marginLeft: "90px" }}
                />
              </div>
            </Link>
          );
        })}
        <LoadMore nextPage={nextPage} loading={loading} fetchMoreFunction={fetchMoreVarieties} />
        <LoadProgress loading={loading} />
      </List>
      {!loading && filteredVarieties.length === 0 && (
        <div>
          <div>{t('nothing_found')}</div>
          <div>
            <Link href={`/${encodeURIComponent(plant || 'fialki')}/add`}>
              <Button size="large" mode="secondary">
                {t('add_variety')}
              </Button>
            </Link>
          </div>
          <Similar search={search} />
        </div>
      )}
    </React.Fragment>
  )
}