import useTranslation from "next-translate/useTranslation";
import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import axios from "axios";
import { API_URL } from "./settings";
import Link from "next/link";

export default function Similar({ search='', loading=false, fetchMoreFunction }) {
  const {t, lang} = useTranslation('common');
  const [sim, setSim] = useState([]);

  useEffect(() => {
    if (search.length>0 && !loading) {
      loadSimilar();
    }
  },[search]);

  async function loadSimilar() {
    console.log("similar to "+ search);
    axios
      .get(
        `${API_URL}/varieties/similar/?name=${search}`
      )
      .then((res) => {
        if (res && res.data && res.data.results) {
          setSim(res.data.results);
          console.log(res.data.results);
          console.table(res.data.results);
        }
      })
      .catch((e) => {
        console.log("can't get similars");
      })
      .finally((e) => {
        //setLoading(false);
      });
  }



  return (
    <React.Fragment>
      {sim.length>0 && !loading && (
        <Box sx={{ paddingTop: "5px", paddingBottom: "5px"}}>
          <p>Возможно, вы имели в виду:</p>
          {sim.map(si=>{
            return (
              <div key={si.id}>
                <Link href={"/fialki/"+si.slug}>{si.name}</Link>
              </div>
            )

          })}
        </Box>
      )}
    </React.Fragment>
  );
}