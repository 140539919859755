
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    
import Layout from "../../lib/layout";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { API_URL, BACKEND_URL } from "../../lib/settings";
import { useDispatch, useSelector } from "react-redux";
import Link from "next/link";
import PageviewIcon from "@mui/icons-material/Pageview";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import {
  Avatar, Box,
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Tooltip
} from "@mui/material";
import { useTheme } from "@emotion/react";
import dynamic from "next/dynamic";
const FilterDialogFull = dynamic(() => import("../../lib/filterdialogfull"));
import { green } from "@mui/material/colors";
import useTranslation from "next-translate/useTranslation";
import translit from "../../lib/translit";
import LoadMore from "../../lib/loadmore";
import LoadProgress from "../../lib/loadprogress";
import Similar from "../../lib/similar";
import { useRouter } from "next/router";
import ShowFilteredVarieties from "../../lib/showFilteredVarietiesList";

export default function Reestr({ np, varieties }) {
  const { t, lang } = useTranslation('plant')
  const router = useRouter()
  const { plant } = router.query;
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const search = useSelector((state) => state.search);
  const reestrFilterExtended = useSelector(
    (state) => state.reestr_filter_extended
  );
  const reestrFilterType = useSelector((state) => state.reestr_filter_type);
  const reestrFilterSort = useSelector((state) => state.reestr_filter_sort);
  const reestrFilterYear = useSelector((state) => state.reestr_filter_year);
  const reestrFilterWithPhoto = useSelector((state) => state.reestr_filter_withphoto);
  const reestrFilterCanPurchase = useSelector((state) => state.reestr_filter_canpurchase);
  const filter_active = reestrFilterExtended==="true" || reestrFilterType.length>0 || reestrFilterYear || reestrFilterWithPhoto || reestrFilterCanPurchase;

  const filteredVarieties = useSelector((state) => state.filteredVarieties);
  const nextPage = useSelector((state) => state.nextPage);
  const [openFilterDialog, setOpenFilterDialog] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const handleFilterDialogClose = () => {
    setOpenFilterDialog(false);
  };


  useEffect(() => {
    if (filteredVarieties.length == 0) {
      // console.log("eseEffect on start on empty filteredVarieties");
      fetchVarieties();
    }
  }, []);

  const isFirstRun = useRef(true);
  let tOut = useRef(false);

  useEffect(() => {
    // console.log("eseEffect on search");
    if (isFirstRun.current) {
      if (filteredVarieties.length>0 && (filteredVarieties[0].plant_slug===plant)) {
        isFirstRun.current = false;
        return;
      }
    }
    if (tOut.current) clearTimeout(tOut.current)
    tOut.current = setTimeout(() => {
      tOut.current = false;
      fetchVarieties()
    }, 600);
  }, [router, plant, search, reestrFilterSort, reestrFilterExtended, reestrFilterType, reestrFilterWithPhoto, reestrFilterCanPurchase, reestrFilterYear]);

  async function fetchVarieties() {
    //axios.get(`${API_URL}/varieties/?plant__slug=senpoliya-uzambarskaya-fialka&ordering=${filterOrdering}&extended=${filterExtended}&search=${searchString}`)
    setLoading(true);
    let sz = reestrFilterType
      .map((v) => {
        let res = "&size=" + v;
        if (v === "standard") res += "&size=null";
        return res;
      })
      .join("");
    axios
      .get(
        //`${API_URL}/varieties/?plant__slug=senpoliya-uzambarskaya-fialka&ordering=${reestrFilterSort}&extended=${reestrFilterExtended}&search=${search}&size=${reestrFilterType}`
        `${API_URL}/varieties/?plant__slug=${plant}&ordering=${reestrFilterSort}&extended=${reestrFilterExtended}&withphoto=${reestrFilterWithPhoto}&canpurchase=${reestrFilterCanPurchase}&year_sel=${reestrFilterYear}&search=${search}&lang=${lang}${sz}`
      )
      .then((res) => {
        if (res && res.data && res.data.results) {
          dispatch({
            type: "SETFILTEREDVARIETIES",
            filteredVarieties: res.data.results,
          });
          dispatch({
            type: "SETNEXTPAGE",
            nextPage: res.data.next,
          });
          //console.log("next:", res.data.results);
        }
      })
      .catch((e) => {
        console.log("can't get varts");
        // console.log(e);
      })
      .finally((e) => {
        setLoading(false);
      });
  }

  async function fetchMoreVarieties() {
    if (nextPage) {
      setLoading(true);
      axios
        .get(nextPage)
        .then((res) => {
          if (res && res.data && res.data.results) {
            dispatch({
              type: "SETFILTEREDVARIETIES",
              filteredVarieties: [...filteredVarieties].concat(
                res.data.results
              ),
            });
            dispatch({
              type: "SETNEXTPAGE",
              nextPage: res.data.next,
            });
            // console.log(res.data.next);
          }
        })
        .catch((e) => {
          console.log("can't get more varts");
        })
        .finally((e) => {
          setLoading(false);
        });
    }
  }

  return (
    <Layout
      title={t(plant)+', '+t('index_title')}
      description={t(plant)+', '+t('index_description')}
      backIcon={true}
    >
      <div>
        <TextField
          id="search"
          //label="Введите название сорта"
          placeholder={
            reestrFilterExtended === "true"
              ? t("extended_search")
              : t("name_search")
          }
          type="search"
          variant="outlined"
          size="small"
          fullWidth
          margin="dense"
          value={search}
          sx={{ backgroundColor: theme.palette.back.searchBack }}
          //style={{backgroundColor: '#e5e5e5'}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon/>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={t('search_settings')}>
                  <TuneIcon
                    onClick={(e) => {
                      setOpenFilterDialog(true);
                    }}
                    style={

                      filter_active
                        ? { cursor: "pointer", color: green[500] }
                        : {cursor: "pointer",}
                    }
                  />
                </Tooltip>
              </InputAdornment>
            ),
          }}
          onChange={(e) => {
            dispatch({
              type: "SEARCH",
              search: e.target.value.replace(/`/g, "'"),
              isFirstRun,
            });
          }}
        />
        <FilterDialogFull
          id="filter-dialog"
          keepMounted
          open={openFilterDialog}
          onClose={handleFilterDialogClose}
          place={'reestr'}
        />
        <ShowFilteredVarieties filteredVarieties={filteredVarieties} loading={loading} nextPage={nextPage} fetchMoreVarieties={fetchMoreVarieties}/>
      </div>
    </Layout>
  );
}

 async function _getStaticProps(params) {
  // Call an external API endpoint to get posts.
  // You can use any data fetching library
  const res = await fetch(`${API_URL}/varieties`);
  const vars = await res.json();
  const varieties = vars.results;
  const np = vars.next;

  // By returning { props: { posts } }, the Blog component
  // will receive `posts` as a prop at build time
  return {
    props: {
      np,
      varieties,
    },
  };
}

export async function getStaticPaths() {
  const res = await fetch(
    `${API_URL}/varieties/?plant__slug=fialki&ordering=-id&limit=20`
  );
  const vars = await res.json();
  const varieties = vars.results;
  const paths = varieties.map((variety) => ({
    params: { plant: variety.plant_slug },
  }));
  return { paths, fallback: "blocking" };
}

    export async function getStaticProps(ctx) {
        let res = _getStaticProps(ctx)
        if(typeof res.then === 'function') res = await res
        return {
          ...res,
          props: {
            ...(res.props || {}),
            ...(await __loadNamespaces({
              ...ctx,
              pathname: '/[plant]/index',
              loaderName: 'getStaticProps',
              ...__i18nConfig,
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
            }))
          }
        }
    }
  